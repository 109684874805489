import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { useLatestPosts } from '../GraphQl/useLatestPosts';
import Layout from '../components/layout';
import { PageMeta } from '../components/pageMeta';
import { PortableTextBlock } from '../components/sanity/portableTextBlock';
import { NewsCard } from '../components/ui/newsCard';
import EmailIcon from '../images/email-icon.svg';
import FacebookIcon from '../images/facebook-icon.svg';
import LinkedinIcon from '../images/linkedin-icon.svg';
import TwitterIcon from '../images/twitter-icon.svg';

const OtherInsights = ({ otherPosts, currentId }) => {
  otherPosts = otherPosts.slice(0, 5);
  let count = 0;

  return (
    <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-10 mt-24'>
      {otherPosts.map((post, i) => {
        if (post.id !== currentId && count < 4) {
          count++;
          return <NewsCard key={i} {...post} />;
        }
        return null;
      })}
    </div>
  );
};

const PostTemplate = ({ data: { post } }) => {
  const { href } = useLocation();
  const { heading, author, date, _rawImage, content, id, meta } = post || {};

  const otherPosts = useLatestPosts();

  return (
    <Layout>
      <PageMeta {...meta} />
      <div className='pt-32 lg:pt-60 text-dark-navy grid grid-cols-14'>
        <div className='col-start-2 lg:col-start-5 col-end-14 lg:col-end-11 text-center'>
          {heading && (
            <h1 className='leading-12 lg:leading-16 blockH1 text-teal'>
              {heading}
            </h1>
          )}
          {author && date && (
            <p className='mt-7 font-bold text-teal'>
              by {author} {date}
            </p>
          )}
        </div>

        {_rawImage && (
          <div className='col-start-2 lg:col-start-3 col-end-14 lg:col-end-13 mt-32'>
            <div className='relative aspect-w-6 aspect-h-4'>
              <Image
                className='w-full absolute top-0 left-0 object-cover'
                {..._rawImage}
              />
            </div>
          </div>
        )}

        {content && (
          <div className='mt-32 col-start-2 col-end-14 lg:col-start-4 lg:col-end-12'>
            <PortableTextBlock
              text={content}
              className='prose prose-base lg:prose-xl'
            />
          </div>
        )}

        <div className='mt-32 mb-24 col-start-2 col-end-14 lg:col-start-4 lg:col-end-12 flex flex-col lg:flex-row items-center justify-center gap-8'>
          <div className='w-full'>
            <p className='text-xl text-center lg:text-left'>Share article:</p>
          </div>

          <div className='w-full flex justify-evenly lg:justify-end'>
            <EmailShareButton url={href}>
              <EmailIcon className='h-7 w-full mr-5' />
            </EmailShareButton>

            <FacebookShareButton url={href}>
              <FacebookIcon className='h-7 w-full mr-5' />
            </FacebookShareButton>

            <TwitterShareButton url={href}>
              <TwitterIcon className='h-7 w-full mr-5' />
            </TwitterShareButton>

            <LinkedinShareButton url={href}>
              <LinkedinIcon className='h-7 w-full' />
            </LinkedinShareButton>
          </div>
        </div>
      </div>
      <div className='bg-white grid grid-cols-14'>
        <div className='col-start-2 col-end-14 pt-24 pb-32'>
          <h2 className='text-teal blockH3 text-center'>Other Insights</h2>
          <OtherInsights otherPosts={otherPosts} currentId={id} />
        </div>
      </div>
    </Layout>
  );
};

export default PostTemplate;

export const postQuery = graphql`
  query standardPostQuery($slug: String!) {
    post: sanityNewsArticle(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      meta {
        _key
        _type
        metaTitle
        metaDescription
        _rawMetaImage
      }
      content: _rawContent(resolveReferences: { maxDepth: 100 })
      heading
      id
      category {
        slug {
          current
        }
      }
      author
      date
      _rawImage(resolveReferences: { maxDepth: 100 })
    }
  }
`;
